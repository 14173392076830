<template>
  <nav class="fixed top-0 py-2 w-full z-20 bg-white md:bg-transparent">
    <div class="nav-wrapper">
      <div class="flex-none">
        <router-link to="/">
          <img class="w-32 rounded-md" src="./../../assets/img/jambo.jpeg" alt="logo" />
        </router-link>
      </div>
      <div class="block md:hidden">
        <div @click="menuMobile">
          <menu-icon />
        </div>
      </div>

      <div class="hidden md:block flex-initial">
        <ul class="nav-item-wrapper">
          <li class="text-white hover:text-white flex items-center">
            <router-link to="/product" class="ml-1">
            <img src="../../assets/svg/fridge.svg" alt="as" class="icon" />
              {{ $t("menu[1]") }}
            </router-link>
          </li>
          <li class="text-white hover:text-white">
            <router-link to="/about">
              <img src="../../assets/svg/aboutus.svg" alt="as" class="icon" />
              {{ $t("menu[2]") }}
            </router-link>
          </li>
          <li class="text-white hover:text-white">
            <router-link to="/contact">
              <img src="../../assets/svg/contact.svg" alt="as" class="icon" />
              {{ $t("menu[3]") }}
            </router-link>
          </li>
          <li class="text-white hover:text-white">
            <a href="https://www.instagram.com/jambo.trade/" target="_blank">
              <img src="../../assets/svg/Instagram.svg" alt="as" class="icon" />
              Instagram
            </a>
          </li>
          <li class="hidden relative">
            <button @click.prevent="menuResponse" class="lang-button">
              <language-icon />
              <span class="px-1">{{ $t("lang") }}</span>
              <chevron-down />
            </button>
            <div
              v-if="dropdown"
              class="absolute mt-2 border-2 shadow-md bg-white"
            >
              <div class="lang-item" @click="changeLang('id')">
                Қазақша
              </div>
              <div class="lang-item" @click="changeLang('en')">Русский</div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!-- Mobile Menu -->
    <div v-if="menuMobileState" class="block md:hidden border-t-2">
      <div class="grid gap-2 w-full p-4">
        <div class="w-full py-1" @click="menuMobile">
          <router-link to="/" class="w-min text-xl">{{
            $t("menu[0]")
          }}</router-link>
        </div>
        <div class="w-full py-1" @click="menuMobile">
          <router-link to="/product" class="w-min text-xl">{{
            $t("menu[1]")
          }}</router-link>
        </div>
        <div class="w-full py-1" @click="menuMobile">
          <router-link to="/about" class="w-min text-xl">{{
            $t("menu[2]")
          }}</router-link>
        </div>
        <div class="w-full py-1" @click="menuMobile">
          <router-link to="/contact" class="w-min text-xl">{{
            $t("menu[3]")
          }}</router-link>
        </div>
      </div>
<!--      <div-->
<!--        class="flex px-4 justify-between items-center space-x-4 border-t-2 pt-2"-->
<!--      >-->
<!--        <button @click="changeLang('id')" :class="activeLang == 'id' ? 'bg-gray-400' : 'bg-gray-800'" class="flex-1 py-2 rounded-lg bg-gray-800 shadow-md font-medium focus:bg-gray-400 focus:ring-4 ring-gray-300 ring-inset">Қазақша</button>-->
<!--        <button @click="changeLang('en')" :class="activeLang == 'en' ? 'bg-gray-400' : 'bg-gray-800'"  class="flex-1 py-2 rounded-lg bg-gray-800 shadow-md font-medium focus:bg-gray-400 focus:ring-4 ring-gray-300 ring-inset">Русский</button>-->
<!--      </div>-->
    </div>
  </nav>
</template>

<script>
import LanguageIcon from "./../icons/LanguageIcon.vue";
import ChevronDown from "./../icons/ChevronDown.vue";
import MenuIcon from "./../icons/MenuIcon.vue";
// import FreezerSvg from '../../assets/svg/freezer.svg';
export default {
  name: "Navigation",
  components: { LanguageIcon, ChevronDown, MenuIcon },
  data() {
    return {
      activeLang: this.$i18n.getLocale(),
      dropdown: false,
      menuMobileState: false,
    };
  },
  methods: {
    changeLang(lang) {
      this.$i18n.setLocale(lang);
      this.menuResponse();
      this.menuMobile();
      this.activeLang = lang;
    },
    menuResponse() {
      this.dropdown = !this.dropdown;
    },
    menuMobile() {
      this.menuMobileState = !this.menuMobileState;
    },
    close(e) {
      if (!this.$el.contains(e.target)) {
        this.dropdown = false;
        this.menuMobileState = false;
      }
    },
  },
  mounted() {
    document.addEventListener("click", this.close);
  },
  unmounted() {
    document.removeEventListener("click", this.close);
  },
};
</script>

<style lang="postcss">
.active {
  @apply border-b-2 border-gray-500 text-white;
}

.lang-button {
  @apply flex px-3 py-1 bg-gray-400 items-center font-semibold rounded-full text-white hover:bg-gray-500 focus:ring-4 focus:ring-gray-300;
}

.nav-wrapper {
  @apply flex w-full py-4 items-center justify-between px-4 sm:px-6 md:px-10 lg:px-36;
}
.icon {
  width: 1.5rem; /* Adjust the width to make the icon smaller */
  height: 1.5rem; /* Adjust the height to make the icon smaller */
  display: inline-block; /* Ensure the icon is treated as a block element */
  vertical-align: middle; /* Align the icon vertically with the text */
}
.nav-item-wrapper {
  @apply flex cursor-pointer space-x-6 text-lg font-semibold items-center;
}

.lang-item {
  @apply select-none py-2 px-4 block text-sm font-medium hover:text-white focus:text-white hover:bg-gray-800 focus:bg-gray-800 focus:outline-none;
}
</style>
