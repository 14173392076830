<template>
  <footer class="bg-gray-900">
    <div class="px-4 md:px-10 lg:px-36 py-14">
      <div class="flex flex-col md:flex-row items-start justify-between gap-10">
        <div class="flex-1">
          <img src="./../../assets/img/jambo.jpeg" class="w-44 rounded-md" alt="Logo" />
          <p class="font-medium text-lg pt-2 text-white">{{ $t("address") }}</p>
          <p class="text-lg text-white">{{ $t("city") }}</p>
        </div>
        <div class="">
          <p class="text-xl font-semibold text-white">Меню</p>
          <ul class="flex flex-col text-lg font-light space-y-1 mt-2 text-white cursor-pointer">
            <a href="/" class="hover:text-white hover:underline">{{ $t("menu[0]") }}</a>
            <a href="/product" class="hover:text-white hover:underline">{{ $t("menu[1]") }}</a>
            <a href="/about" class="hover:text-white hover:underline">{{ $t("menu[2]") }}</a>
            <a href="/contact" class="hover:text-white hover:underline">{{ $t("menu[3]") }}</a>
            <!-- <a href="/admin" class="hover:text-white hover:underline">Администратор</a> -->
          </ul>
        </div>
        <div class="">
          <p class="text-xl font-semibold text-white">Социальные сети</p>
          <ul class="flex flex-col text-lg font-light space-y-1 mt-2 text-white cursor-pointer">
<!--            <a href="https://facebook.com/" class="hover:text-white hover:underline">Facebook</a>-->
            <a href="https://instagram.com/jambo.trade" class="hover:text-white hover:underline">Instagram</a>
            <a href="https://wa.me/+77474655977" class="hover:text-white hover:underline">Whatsapp</a>
          </ul>
        </div>
      </div>
    </div>
    <div class="bg-gray-700 py-2">
      <p class="text-center text-lg font-medium text-white">
      <!-- copyright -->
      </p>
    </div>
  </footer>
</template>