<template>
  <div class="bg-gray-800">
    <navigation-bar
      :class="{ 'bg-transpatent shadow-md': scrollIsActive > 1 }"
      style="background-color: rgba(0, 0, 0, 0.5)"
    />
    <router-view />
    <the-footer />
  </div>
</template>

<script>
import sal from 'sal.js';
import NavigationBar from "./../../components/landing_pages/NavigationBar.vue";
import TheFooter from "./../../components/commons_component/TheFooter.vue";

export default {
  name: "LandingPage",
  components: {
    NavigationBar,
    TheFooter,
  },
  data() {
    return {
      scrollIsActive: null,
    };
  },
  methods: {
    updateScroll() {
      this.scrollIsActive = window.scrollY;
    },
  },
  mounted() {
    sal();
    window.addEventListener("scroll", this.updateScroll);
  },
};
</script>

<style lang="postcss">
.form-input {
  @apply rounded-lg
              ring-inset
              focus:outline-none
              focus:ring-2
              focus:ring-gray-400
              focus:border-transparent
              bg-white
              text-black
              px-4
              py-3
              w-full
}

.submit-button {
  @apply px-6
            py-3
            text-white
            rounded-lg
            font-bold
            bg-gray-900
            hover:bg-gray-800 hover:shadow-md
}
</style>
